import * as axiosService from "./axiosRequests";
import { endpoint } from "../utils/endpoint";
import { IMassUpdate, IMassUpdateAttributes } from "../types/IMassUpdate";

export type IMassUpdatePayload = Partial<{
  configuration: IMassUpdateAttributes["configuration"];
  lead_ids: IMassUpdateAttributes["leadIds"];
  type: IMassUpdateAttributes["type"];
}>;

const massUpdateEndpoint = (massUpdateId: string) => endpoint(`app/v1.0/mass_updates/${massUpdateId}`);
const massUpdateExecuteEndpoint = (massUpdateId: string) => endpoint(`app/v1.0/mass_updates/${massUpdateId}/execute`);

export const getMassUpdateAsync = async (massUpdateId: string): Promise<IMassUpdate> => {
  const response = await axiosService.axiosGet(
    massUpdateEndpoint(massUpdateId),
  );

  return response.data;
};

export const putMassUpdateAsync = async (
  massUpdateId: string,
  payload: Partial<IMassUpdatePayload>,
): Promise<IMassUpdate> => {
  const response = await axiosService.axiosPut(
    massUpdateEndpoint(massUpdateId),
    payload,
  );

  return response.data;
};

export const deleteMassUpdateAsync = async (
  massUpdateId: string,
) => {
  await axiosService.axiosDelete(
    massUpdateEndpoint(massUpdateId),
  );
};

export const executeMassUpdateAsync = async (
  massUpdateId: string,
): Promise<void> => {
  await axiosService.axiosPost(
    massUpdateExecuteEndpoint(massUpdateId),
  );
};

export default {
  getMassUpdateAsync,
  putMassUpdateAsync,
  executeMassUpdateAsync,
  deleteMassUpdateAsync,
};
