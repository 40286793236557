export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
export const integrationBaseUrl = process.env.REACT_APP_INTEGRATION_SERVICE_BASE_URL;

export const awsServicesBaseUrl = process.env.REACT_APP_AWS_SERVICES_BASE_URL;

export const endpoint = (action: string) => `${baseUrl}/${action}`;

export const integrationEndpoint = (action: string) => `${integrationBaseUrl}/${action}`;

export const awsServicesEndpoint = (service: string, action: string) => `${awsServicesBaseUrl}/${service}/v1/${action}`;

const WEBSOCKET_URL_PREFIX_UNSECURE = "ws://";
const WEBSOCKET_URL_PREFIX_SECURE = "wss://";

const WEBSOCKET_URL_PREFIX = process.env.NODE_ENV === "development"
  ? WEBSOCKET_URL_PREFIX_UNSECURE
  : WEBSOCKET_URL_PREFIX_SECURE;

const WEBSOCKET_BASE_URL = baseUrl?.replace(/^.*:\/\//, WEBSOCKET_URL_PREFIX);

export const WEBSOCKET_URL = `${WEBSOCKET_BASE_URL}/cable`;
