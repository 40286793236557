import {
  useCallback, useContext, useEffect, useState,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AppContext } from "../context";
import { WEBSOCKET_URL } from "../utils/endpoint";

export const SUBSCRIBE_COMMAND = "subscribe";
export const UNSUBSCRIBE_COMMAND = "unsubscribe";
const PING = "ping";

export interface IWebSocketJsonMessage {
  identifier?: string;
  message: Record<string, unknown>;
}

export const useAuthWebSocket = (
  isActive: boolean,
  channel: string,
  id?: string,
  url: string = WEBSOCKET_URL,
) => {
  const { currentUser } = useContext(AppContext);
  const [message, setMessage] = useState<IWebSocketJsonMessage["message"]>({});

  const authToken = currentUser?.authToken;

  const channelIdentifier = JSON.stringify({ channel, id });

  const { lastJsonMessage, readyState, sendJsonMessage } = useWebSocket<IWebSocketJsonMessage>(
    url,
    {
      filter: (lastMessage) => {
        try {
          const data = JSON.parse(lastMessage.data);
          return data.type !== PING;
        } catch {
          return true;
        }
      },
      queryParams: { auth_token: authToken },
    },
  );

  const subscribe = useCallback(() => {
    if (isActive && readyState === ReadyState.OPEN) {
      sendJsonMessage({
        command: SUBSCRIBE_COMMAND,
        identifier: channelIdentifier,
      });
    }
  }, [isActive, channelIdentifier, readyState, sendJsonMessage]);

  const unsubscribe = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        command: UNSUBSCRIBE_COMMAND,
        identifier: channelIdentifier,
      });
    }
  }, [channelIdentifier, readyState, sendJsonMessage]);

  useEffect(() => {
    if (isActive) {
      subscribe();
    }
    return () => unsubscribe();
  }, [isActive, subscribe, unsubscribe]);

  useEffect(() => {
    if (!lastJsonMessage) return;

    const validMessage = lastJsonMessage?.message;
    if (validMessage) {
      if ((validMessage?.message as Record<string, boolean>)?.end_stream) {
        unsubscribe();
        return;
      }
      setMessage(lastJsonMessage?.message);
    }
  }, [lastJsonMessage, unsubscribe]);

  return { message };
};

export default useAuthWebSocket;
